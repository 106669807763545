import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/homepage";
import { Projects } from "./components/projects";
import { Alert } from "react-bootstrap";

function App() {
  return (
    <>
          <Alert
        variant="info"
        style={{
          transform: "rotate(-45deg)",
          textAlign: "left",
          width: "100vw",
        }}
      >
        {"In Progress"}
      </Alert>
      <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/projects"} element={<Projects />} />
      </Routes>
    </BrowserRouter>
    </>
  
  );
}

export default App;
