import React from "react";
import LeftNav from "./left-nav";
import strings from "../data/strings.json";
import "../css/about-me.css";
import image from "../img/image-12.jpg";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { Alert, Col, Row } from "react-bootstrap";

export function Home() {
  return (
    <Container>
      <Row>
        <Col md={3} xs={0}>
          <LeftNav />
        </Col>
        <Col className="col-6">
          <p style={{ textAlign: "center", position: "relative" }}>
            {strings["about-bethwel"]}
          </p>
        </Col>
        <Col xs={12} md={3}>
          <Image roundedCircle className="about-me-img" alt="" src={image} />
        </Col>
      </Row>
    </Container>
  );
}
