import React from "react";
import { NavLink } from "react-router-dom";
import "../css/left-nav.css";

const LeftNav = () => {
  return (
    <nav className="nav">
      <ul>
        <li>
          <NavLink to="/projects">Projects</NavLink>
        </li>
        <li>
          <NavLink to="/about-me">About Me</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li>
          <NavLink to="/musings">Musings</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default LeftNav;
